import '../../utils/marketingPageDataFragment';

import { GreenBlob, GreenSplatsComplete, RedSplatsComplete } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import MediaQuery from 'react-responsive';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import FaqModule, { FaqModuleDataPropsObject } from '../../components/FaqModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import IconListWithMediaModule, { IconListWithMediaDataPropsObject } from '../../components/IconListWithMediaModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';
import {
  community as communityClass,
  copyLockupList as copyLockupListClass,
  greenBlob,
  greenSplatsComplete,
  hero as heroClass,
  iconListWithMedia as iconListWithMediaClass,
  iconListWithMediaDefault,
  iconListWithMediaLeft,
  redSplatsComplete
} from './MarketingFeaturesPage.module.css';

export default function MarketingFeaturesPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    community,
    copyLockupList,
    frequentlyAskedQuestionsFaq,
    iconListWithMedia,
    pitch,
    hero
  } = componentsQueryResultToData(components);

  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName, hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      <IconListWithMediaModule align="right" className={iconListWithMediaClass} data={iconListWithMedia[0]} />
      <IconListWithMediaModule align="left" className={iconListWithMediaLeft} data={iconListWithMedia[1]} />
      <IconListWithMediaModule className={iconListWithMediaDefault} data={iconListWithMedia[2]} />
      <CopyLockupListModule childrenLayout="centered" className={copyLockupListClass} data={copyLockupList} />
      <CommunityModule className={communityClass} data={community} />
      {frequentlyAskedQuestionsFaq && (
        <FaqModule
          data={frequentlyAskedQuestionsFaq}
          hasMarginBottom
        />
      )}
      <PitchModule
        blobs={(
          <>
            <LazyLoad once>
              <MediaQuery maxWidth={767}>
                <GreenSplatsComplete className={greenSplatsComplete} />
              </MediaQuery>
              <MediaQuery minWidth={768}>
                <GreenBlob className={greenBlob} />
                <RedSplatsComplete className={redSplatsComplete} />
              </MediaQuery>
            </LazyLoad>
          </>
        )}
        data={pitch}
        hideBottomWave
      />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingFeaturesPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingFeaturesPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFrequentlyAskedQuestionsFaq']),
          ...FaqModuleDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentIconListWithMedia']),
          ...IconListWithMediaDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
