// extracted by mini-css-extract-plugin
export var base = "MarketingFeaturesPage-module--base--1F_sg";
export var rightAlignedContainer = "MarketingFeaturesPage-module--rightAlignedContainer--1x_TR";
export var rightAlignedContainerSmall = "MarketingFeaturesPage-module--rightAlignedContainerSmall--2v67I";
export var leftAlignedContainer = "MarketingFeaturesPage-module--leftAlignedContainer--106PD";
export var blogContainer = "MarketingFeaturesPage-module--blogContainer--3u1GZ MarketingFeaturesPage-module--base--1F_sg";
export var container = "MarketingFeaturesPage-module--container--CfdnE MarketingFeaturesPage-module--base--1F_sg";
export var largeContainer = "MarketingFeaturesPage-module--largeContainer--3uxeD MarketingFeaturesPage-module--base--1F_sg";
export var mobileSmallPadding = "MarketingFeaturesPage-module--mobileSmallPadding--HzcXc";
export var fullWidthMobile = "MarketingFeaturesPage-module--fullWidthMobile--2YP04";
export var gridWithSidebar = "MarketingFeaturesPage-module--gridWithSidebar--9c2xR";
export var noMaxWidth = "MarketingFeaturesPage-module--noMaxWidth--72lbb";
export var community = "MarketingFeaturesPage-module--community--1SKPE";
export var copyLockupList = "MarketingFeaturesPage-module--copyLockupList--tHPMb";
export var iconListWithMedia = "MarketingFeaturesPage-module--iconListWithMedia--34eZj";
export var iconListWithMediaLeft = "MarketingFeaturesPage-module--iconListWithMediaLeft--ySbxt";
export var iconListWithMediaDefault = "MarketingFeaturesPage-module--iconListWithMediaDefault--2QPDM";
export var hero = "MarketingFeaturesPage-module--hero--3B3M1";
export var greenSplatsComplete = "MarketingFeaturesPage-module--greenSplatsComplete--1Ks7h";
export var greenBlob = "MarketingFeaturesPage-module--greenBlob--3SFu_";
export var redSplatsComplete = "MarketingFeaturesPage-module--redSplatsComplete--3ugUx";